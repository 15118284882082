<template>
  <v-card class="mx-auto my-4" rounded="lg" :width="windowsSize" :color="isSelected ? 'selected' : 'mobileCard'">
    <v-card-title class="d-flex justify-start py-0">
      <v-checkbox 
        :label="item.name | truncate(38)"
        color="primary"
        @change="select($event)"
        :input-value="isSelected">
      </v-checkbox>
    </v-card-title>
    <v-img 
      v-if="item.image != null"
      max-height="250"
      @click="editItem(item)"
      class="cursor-pointer"
      :src="require(`@/assets/${item.image}`)" 
      :alt="item.slug">
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center">
          <v-progress-circular
            size="35"
            indeterminate
            width="2"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-card @click="editItem(item)" v-else elevation="0" tile min-height="160" :color="$vuetify.theme.dark ? 'drawernumber' : 'grey lighten-4'">
      <v-card-text>
        <v-card :class="$vuetify.theme.dark ? 'custom-card2' : 'custom-card'" height="160" outlined elevation="0">
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center">
            <v-icon size="60" :color="$vuetify.theme.dark ? 'drawerbackground' : 'drawernumber'">
              mdi-image
            </v-icon>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
    <v-card-text class="body-1">
      <p class="text-justify" v-html="item.synopsis"></p>
      <span class="subtitle-2">{{ $tc('blog.AUTOR', 1)}}: {{ item.autor.name.first }} {{ item.autor.name.last }}</span>
    </v-card-text>
    <v-divider
      class="mx-4">
    </v-divider>
    <v-card-actions>
      <span class="ml-2 subtitle-2">{{ $t('PUBLISHED') }} • {{ fortmatDate(item.published) }}</span>
      <v-spacer></v-spacer>
      <v-btn large elevation="0" icon @click="editItem(item)" dark color="primary">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-menu
        offset-x
        nudge-top="74"
        bottom
        left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            large
            icon
            v-bind="attrs"
            v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group color="primary">
            <v-list-item to="/">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-content-copy</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('blog.DUPLICATENTRY') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-autorenew</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('blog.BACKTODRAFT') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="deleteItem(item)">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('blog.SENDTOTRASH') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-card-actions>
  </v-card>
</template>

<script>
import utils from '@/mixins/globalUtils.js'

export default {
  name: "blog-card",
  props:{
    item: Object,
    select: Function,
    isSelected: {
      type: Boolean,
      default: false,
    },
    windowsSize: Number,
  },
  data() {
    return {
    }
  },
  methods: {
    editItem (item) {
      this.$emit('editItem', item)
    },
    deleteItem (item) {
      this.$emit('deleteItem', item)
    },
    fortmatDate(value) {
      return utils.fortmatDate(value, this.$i18n.locale)
    }
  },
}
</script>

<style scoped>
.cursor-pointer:hover  {
  cursor: pointer;
}
.v-card {
  width: 100%;
}
.custom-card {
  background:none;
  border: #cccccc dotted 2px;
}
.custom-card2 {
  background:#444444;
  border: #222222 dotted 2px;
}
</style>
